import * as React from 'react'
// import * as Leaflet from 'react-leaflet'
import L from 'leaflet';
import { MapContainer, TileLayer, useMap, Marker, Popup, ImageOverlay, Circle, Polygon, Polyline } from 'react-leaflet'
import { Container } from 'semantic-ui-react';
import 'leaflet/dist/leaflet.css';
// import './map.css'
// import {
//   QueryClient,
//   QueryClientProvider,
//   useQuery,
// } from '@tanstack/react-query';
import { divIcon } from 'leaflet';
// import { renderToStaticMarkup } from 'react-dom/server';
// import { useState } from 'react';

const Sector = (props) => {
  // console.log("Sector props:", props);
  const coordinates = JSON.parse(props.sector.coordinates).map((coordinate) => {
    return [
      parseInt(coordinate[0]) * parseFloat(props.scale) + parseInt(props.offsetX),
      parseInt(coordinate[1]) * parseFloat(props.scale) + parseInt(props.offsetY)
    ];
  });
  // color is in RGB format, sector.r, sector.g, sector.b
  const color = `grey`;
  return (
    <Polygon pathOptions={{color: props.sector.owner_color}} positions={coordinates}>
      <Popup>
        <div>
          <h3>{props.sector.name}</h3>
          <p>Owner: {props.sector.owner}</p>
          <ul>
            {props.sector.planets.map((planet, index) => {
              return (
                <li key={index}>{planet.name}</li>
              );
            })}
          </ul>
        </div>
      </Popup>
    </Polygon>
  );
}

// end_x: -220
// ​​​​​end_y: -255
// ​​​​​start_x: -235
// ​​start_y: -250
const HyperspaceLane = ({start_x, start_y, end_x, end_y, scale, offsetX, offsetY}) => {
  const coordinates = [[
    parseInt(start_x) * parseFloat(scale) + parseInt(offsetX),
    parseInt(start_y) * parseFloat(scale) + parseInt(offsetY)
  ], [
    parseInt(end_x) * parseFloat(scale) + parseInt(offsetX),
    parseInt(end_y) * parseFloat(scale) + parseInt(offsetY)
  ]];
  return (
    <Polyline pathOptions={{color: 'blue'}} positions={coordinates}/>
  );
}
      


const GalaxyMap = (props) => {
  // console.log('GalaxyMap props:', props);
  // const { isPending, error, data } = useQuery({
  //   refetchInterval: 6000,
  //   queryKey: ['ships', props.star_system.id],
  //   queryFn: () =>
  //     fetch(`/admin/star_systems/${props.star_system.id}/ships.json`).then((res) => res.json()
  //     ),
  // });
  // console.log('ships', data);
  const [scale, setScale] = React.useState(0.2);
  const [offsetX, setOffsetX] = React.useState(-122.5);
  const [offsetY, setOffsetY] = React.useState(121.5);
  return (
    <Container>

      <MapContainer
        zoom={2}
        minZoom={0}
        maxZoom={10}
        scrollWheelZoom={false}
        style={{
          height: '600px',
          maxHeight: '600px',
          maxWidth: '800px'
          // float: 'left'
        }}
        center={[-offsetY, -offsetX]}
        crs={L.CRS.Simple}
      >
        <TileLayer
          url='https://legacyofthesith.com/tiles/{z}/{x}/{y}.png'
          >

        </TileLayer>
        {props.sectors.map((sector, index) => {
          return (
            <Sector key={index} sector={sector} scale={scale} offsetX={offsetX} offsetY={offsetY} />
          );
        })}
        {/* {props.lanes.map((hyperspace_lane, index) => {
          return (
            <HyperspaceLane key={index} {...hyperspace_lane} scale={scale} offsetX={offsetX} offsetY={offsetY} />);
        })} */}
      </MapContainer>
    </Container>
  )
};

export default GalaxyMap;